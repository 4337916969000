@charset "UTF-8";
/*=============================================
=            모바일            =
=============================================*/
@media screen and (max-width: 1089px) {
  .desk {
    display: none; }
  .invisible {
    display: none; }
  .container {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    padding: 0 8px; }
  .container-support {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    padding: 0 8px; }
  .menu-line {
    height: 1px;
    width: 100%;
    background-color: #d0d0d0;
    margin-top: -5px; }
  .breadcrumb {
    font-family: NotoSansRegular;
    font-size: 14px;
    color: #979797;
    padding: 12px 0px; }
    .breadcrumb-current {
      color: #646464;
      font-weight: bold; }
    .breadcrumb-divider {
      margin: 0px 4px; }
  .top-link {
    height: 35px;
    font-family: NotoSansRegular;
    font-size: 12px;
    color: #333333; }
  .top-link-item {
    float: right;
    padding: 5px 10px;
    color: #333333;
    cursor: pointer; }
  .top-link-divider {
    float: right;
    padding: 5px 10px; }
  .header {
    height: 50px;
    width: 100%; }
    .header-logo {
      float: left;
      padding-top: 13px; }
    .header-logo > img {
      width: 60px; }
    .header-menu {
      float: right;
      padding-top: 13px; }
  .burger-menu-wrapper {
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px; }
  .burger-menu {
    background-color: #fff;
    position: absolute;
    top: 55px;
    right: 25px;
    width: 200px; }
  .menu-burger-btn {
    display: inline; }
  .menu-burger-btn-icon {
    font-size: 20px; }
  .ant-menu {
    font-family: NanumSquareEB !important;
    font-size: 20px !important;
    color: #333333 !important;
    background-color: transparent !important; }
  .ant-menu-item {
    margin-right: 80px; }
  .ant-menu-item > a {
    color: #333333 !important; }
  .ant-menu-item > a:hover {
    color: #00aab6 !important; }
  .ant-menu-submenu-title:hover {
    color: #00aab6 !important; }
  .ant-menu-item-selected {
    color: #00aab6 !important; }
  .ant-menu-item-selected > a,
  .ant-menu-item-selected > a:hover {
    color: #00aab6 !important; }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent !important; }
  .ant-menu-horizontal {
    line-height: 54px !important;
    border-bottom: 5px !important; }
  .ant-menu-horizontal > .ant-menu-item {
    margin: 0px 50px; }
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    padding-bottom: 0px !important;
    border-bottom: 5px solid #00aab6 !important;
    color: #00aab6 !important; }
  .ant-menu-submenu-popup {
    color: #ffffff !important;
    background-color: #090e2c !important; }
  .ant-menu-horizontal {
    border-bottom: 5px solid transparent !important; }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 5px solid transparent; }
  .footer {
    background-color: #000;
    font-family: NotoSansLight;
    font-size: 16px;
    line-height: 24px;
    color: #fff; }
    .footer-left {
      padding-top: 20px; }
    .footer-left > img {
      width: 80px; }
    .footer-right {
      padding: 20px 0px; }
  .purchase-container-right {
    width: 100%;
    margin-top: 20px; }
  .purchase-image-main {
    width: 100%; }
  .purchase-image-sub-list {
    margin: 10px 0px 0px -5px; }
  .purchase-image-sub-wrapper {
    display: inline; }
  .purchase-image-sub {
    width: 30%;
    margin: 0px 5px; }
  .purchase-content-detail-quantity {
    width: 100px; }
  .purchase-content-line {
    height: 1px;
    background-color: #d0d0d0; }
  .purchase-content-title-name {
    display: inline-block;
    font-family: NotoSansRegular;
    font-size: 28px;
    font-weight: bold; }
  .purchase-content-title-engname {
    display: inline-block;
    font-family: NotoSansRegular;
    font-size: 18px;
    color: #bebebe;
    margin-left: 10px; }
  .purchase-content-title-explain {
    font-family: NotoSansMedium;
    font-size: 16px;
    padding-bottom: 15px; }
  .purchase-content-price {
    font-family: NotoSansBlack;
    font-size: 24px;
    color: #00aab6;
    padding: 15px 0px; }
  .purchase-content-detail-wrapper {
    padding: 15px 0px; }
  .purchase-content-detail-item {
    display: inline-block;
    width: 100px;
    font-family: NotoSansMedium;
    font-size: 14px;
    line-height: 30px; }
  .purchase-content-detail-item-explain {
    display: inline-block;
    font-family: NotoSansRegular;
    font-size: 14px;
    line-height: 30px; }
  .purchase-content-totalprice-wrapper {
    padding: 15px 0px; }
  .purchase-content-totalprice-01 {
    display: inline-block;
    font-family: NotoSansBold;
    font-size: 18px; }
  .purchase-content-totalprice-02 {
    display: inline-block;
    font-family: NotoSansRegular;
    font-size: 14px; }
  .purchase-content-totalprice-03 {
    display: inline-block;
    font-family: NotoSansBlack;
    font-size: 18px;
    color: #ff6e00; }
  .purchase-content-button {
    font-family: NanumSquareB;
    font-size: 18px;
    color: #fff;
    width: 100%;
    background-color: #00aab6;
    padding: 12px;
    text-align: center;
    cursor: pointer; }
  .blobscard-top {
    background-image: url("../img/blobscard/main_title_bg.png");
    height: 560px;
    width: 100%;
    margin-top: -5px;
    background-position-x: 50%; }
  .blobscard-title {
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-family: NotoSansMedium;
    font-weight: bold;
    padding-top: 100px; }
  .blobscard-title-explain {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-family: NotoSansRegular;
    padding: 20px 30px; }
  .blobscard-title-button {
    font-family: NanumSquareB;
    font-size: 16px;
    text-align: center;
    padding-top: 20px; }
    .blobscard-title-button-01 {
      color: #fff;
      display: inline-block;
      width: 255px;
      padding: 15px;
      background-color: #00aab6;
      margin: 8px;
      cursor: pointer; }
    .blobscard-title-button-02 {
      color: #fff;
      display: inline-block;
      width: 255px;
      padding: 15px;
      background-color: #000;
      margin: 8px;
      cursor: pointer; }
  .blobscard-content-left {
    width: 350px; }
  .blobscard-content-right {
    width: 350px; }
  .blobscard-content-image {
    width: 330px; }
  .blobscard-content-number {
    font-family: NanumSquareB;
    font-size: 20px;
    color: #00aab6; }
  .blobscard-content-title {
    font-family: NotoSansRegular;
    font-size: 30px;
    color: #333333; }
  .blobscard-content-explain {
    font-family: NotoSansRegular;
    font-size: 20px;
    line-height: 28px;
    color: #646464;
    padding-top: 20px; }
  .blobscard-content-02-wrapper {
    background-color: #f9fafb; }
  .blobscard-content-04-wrapper {
    background-image: url("../img/blobscard/main_content04_bg.png"); }
  .blobscard-purchase-content-tab {
    background-color: #eaeaea;
    border: solid 1px #d0d0d0;
    font-family: NotoSansRegular;
    font-size: 18px;
    color: #333333;
    display: inline-block;
    padding: 14px;
    text-align: center;
    width: 120px;
    cursor: pointer; }
    .blobscard-purchase-content-tab-over {
      background-color: #fff;
      border: solid 1px #d0d0d0;
      font-family: NotoSansBold;
      font-size: 18px;
      color: #333333;
      display: inline-block;
      padding: 14px;
      text-align: center;
      width: 120px;
      cursor: pointer; }
  .support-coin-container {
    margin: 0 auto;
    width: 90%;
    font-size: 14px;
    letter-spacing: -1px;
    color: #575757;
    font-weight: bold;
    text-align: center; }
  .support-coin-wrapper {
    display: inline-block;
    vertical-align: top;
    padding: 20px 0px;
    width: 100px; }
  .support-coin-image {
    width: 78%; }
  .support-coin-name {
    font-family: NotoSansRegular;
    font-size: 12px;
    padding-top: 10px; }
  .blobscard-purchase-coin-title {
    font-family: NotoSansBold;
    font-size: 30px;
    color: #333333;
    padding: 50px 0px 20px 0px; }
    .blobscard-purchase-coin-title-01 {
      display: inline-block; }
    .blobscard-purchase-coin-title-02 {
      display: inline-block;
      color: #00aab6;
      margin-left: 10px; }
  .blobscard-purchase-coin-explain-01 {
    font-family: NotoSansRegular;
    font-size: 16px;
    color: #333333; }
  .blobscard-purchase-coin-explain-02 {
    font-family: NotoSansRegular;
    font-size: 16px;
    color: #ff832a;
    padding-bottom: 0px; }
  .blobscard-purchase-content01 {
    min-height: 600px;
    background-image: url("../img/ethereumcard/purchase_content01_bg.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 0px; }
  .blobscard-purchase-content01-title {
    font-size: 35px;
    color: #00aab6;
    font-family: NotoSansBold;
    text-align: center;
    padding: 50px 0px 25px 0px; }
  .blobscard-purchase-content01-explain {
    font-size: 14px;
    color: #fff;
    font-family: NotoSansRegular;
    text-align: center; }
  .blobscard-purchase-content01-box-wrapper {
    padding: 40px 0px;
    margin: 0px auto;
    width: 300px; }
  .blobscard-purchase-content01-box {
    display: inline-block;
    text-align: center;
    width: 135px;
    height: 135px;
    background-color: #def2f3;
    margin: 6px; }
    .blobscard-purchase-content01-box:nth-child(2n) {
      background-color: #ededed; }
  .blobscard-purchase-content01-box-number {
    font-size: 18px;
    color: #00aab6;
    font-family: NotoSansBlack;
    padding-top: 20px; }
  .blobscard-purchase-content01-box-title {
    font-size: 18px;
    color: #333333;
    font-family: NotoSansBold; }
  .blobscard-purchase-content01-box-detail {
    font-size: 12px;
    color: #646464;
    font-family: NotoSansRegular;
    padding: 20px 5px; }
  .blobscard-purchase-content02-content {
    display: inline-block;
    margin: 10px;
    width: 130px;
    min-height: 250px;
    vertical-align: top; }
  .blobscard-purchase-content03-wrapper {
    background-image: url("../img/blobscard/purchase_content03_bg.png");
    background-repeat: no-repeat;
    height: 300px;
    text-align: center;
    padding-top: 70px;
    margin-bottom: 50px; }
  .blobscard-purchase-content03-title01 {
    font-size: 30px;
    line-height: 30px;
    color: #00aab6;
    font-family: NotoSansRegular; }
  .blobscard-purchase-content03-title02 {
    font-size: 36px;
    line-height: 46px;
    color: #00aab6;
    font-family: NotoSansBold;
    padding-bottom: 20px; }
  .blobscard-purchase-content03-explain {
    font-size: 16px;
    color: #fff;
    font-family: NotoSansRegular; }
  .blobscard-purchase-content04-title01 {
    font-size: 20px;
    color: #333333;
    font-family: NotoSansRegular;
    text-align: center;
    padding-top: 30px; }
  .blobscard-purchase-content04-title02 {
    font-size: 26px;
    color: #00aab6;
    font-family: NotoSansRegular;
    text-align: center; }
  .blobscard-purchase-content04-image {
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center; }
  .blobscard-purchase-content05-wrapper {
    padding-top: 100px; }
  .blobscard-purchase-content05-item {
    color: #646464;
    font-family: NotoSansBold;
    font-size: 16px;
    text-align: center; }
    .blobscard-purchase-content05-item > div {
      padding-top: 20px; }
  .blobscard-purchase-content06-wrapper {
    padding: 50px 0px 0px 0px; }
  .blobscard-purchase-content06-container {
    background-color: #eeeeee;
    width: 100%;
    height: 600px; }
  .blobscard-purchase-content06-item {
    color: #646464;
    font-family: NotoSansRegular;
    font-size: 14px; }
    .blobscard-purchase-content06-item:nth-of-type(1) {
      padding: 10px 10px; }
    .blobscard-purchase-content06-item:nth-of-type(2) {
      padding: 10px 10px; }
    .blobscard-purchase-content06-item > img {
      width: 300px; }
    .blobscard-purchase-content06-item > li {
      padding-top: 20px;
      font-family: NotoSansBold;
      font-size: 16px; }
    .blobscard-purchase-content06-item > div {
      text-indent: 26px; }
  .blobscard-purchase-content07-explain {
    display: none; }
  .blobscard-purchase-content07-container {
    text-align: center;
    padding-bottom: 100px; }
    .blobscard-purchase-content07-container > img {
      width: 300px; }
  .ethereumcard-top {
    background-image: url("../img/ethereumcard/main_title_bg.png");
    height: 560px;
    width: 100%;
    margin-top: -5px; }
  .ethereumcard-title {
    width: 100%;
    font-size: 40px;
    font-family: NotoSansMedium;
    font-weight: bold;
    color: #fff;
    padding-top: 100px; }
  .ethereumcard-title-explain {
    width: 100%;
    font-size: 20px;
    color: #fff;
    font-family: NotoSansRegular;
    padding: 20px 70px 80px 0px; }
  .ethereumcard-title-button {
    font-family: NanumSquareB;
    font-size: 16px;
    padding-top: 20px; }
    .ethereumcard-title-button-01 {
      text-align: center;
      color: #fff;
      display: inline-block;
      width: 255px;
      padding: 15px;
      background-color: #00aab6;
      margin: 8px;
      cursor: pointer; }
    .ethereumcard-title-button-02 {
      text-align: center;
      color: #fff;
      display: inline-block;
      width: 255px;
      padding: 15px;
      background-color: #000;
      margin: 8px;
      cursor: pointer; }
  .ethereumcard-content-gray-wrapper {
    background-color: #f7f7f8; }
  .ethereumcard-purchase-content01 {
    margin-top: 50px;
    min-height: 600px;
    background-image: url("../img/ethereumcard/purchase_content01_bg.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 0px; }
  .ethereumcard-purchase-content01-title {
    font-size: 35px;
    color: #00aab6;
    font-family: NotoSansBold;
    text-align: center;
    padding: 50px 0px 25px 0px; }
  .ethereumcard-purchase-content01-explain {
    font-size: 14px;
    color: #fff;
    font-family: NotoSansRegular;
    text-align: center; }
  .ethereumcard-purchase-content01-box-wrapper {
    padding: 40px 0px;
    margin: 0px auto;
    width: 300px; }
  .ethereumcard-purchase-content01-box {
    display: inline-block;
    text-align: center;
    width: 135px;
    height: 135px;
    background-color: #def2f3;
    margin: 6px; }
    .ethereumcard-purchase-content01-box:nth-child(2n) {
      background-color: #ededed; }
  .ethereumcard-purchase-content01-box-number {
    font-size: 18px;
    color: #00aab6;
    font-family: NotoSansBlack;
    padding-top: 20px; }
  .ethereumcard-purchase-content01-box-title {
    font-size: 18px;
    color: #333333;
    font-family: NotoSansBold; }
  .ethereumcard-purchase-content01-box-detail {
    font-size: 12px;
    color: #646464;
    font-family: NotoSansRegular;
    padding: 20px 5px; }
  .ethereumcard-purchase-slash {
    background-image: url("../img/common/bullet_slash.png");
    background-repeat: repeat-x;
    background-position-y: 50%;
    text-align: center; }
  .ethereumcard-purchase-content02-title {
    font-size: 20px;
    color: #333333;
    font-family: NotoSansBold;
    background-color: #fff;
    display: inline;
    letter-spacing: 5px;
    padding: 20px; }
  .ethereumcard-purchase-content02-content-wrapper {
    padding: 50px 10px;
    text-align: center; }
  .ethereumcard-purchase-content02-content {
    display: inline-block;
    margin: 10px;
    width: 130px;
    min-height: 250px;
    vertical-align: top; }
  .ethereumcard-purchase-content02-content-item01 > img {
    width: 100px; }
  .ethereumcard-purchase-content02-content-item02 {
    text-align: left;
    padding-left: 25px;
    background-image: url("../img/common/bullet_check.png");
    background-repeat: no-repeat;
    background-position-y: 1px;
    color: #646464;
    font-family: NotoSansRegular;
    font-size: 14px;
    margin-top: 20px; }
  .ethereumcard-purchase-content03-wrapper {
    padding-top: 30px; }
  .ethereumcard-purchase-content03-item {
    color: #646464;
    font-family: NotoSansBold;
    font-size: 16px;
    text-align: center; }
    .ethereumcard-purchase-content03-item > div > img {
      width: 300px; }
    .ethereumcard-purchase-content03-item > div {
      padding-top: 20px; }
  .ethereumcard-purchase-content04-wrapper {
    padding: 30px 0px; }
  .ethereumcard-purchase-content04-container {
    background-color: #eeeeee;
    width: 100%;
    height: 450px; }
  .ethereumcard-purchase-content04-item {
    color: #646464;
    font-family: NotoSansRegular;
    font-size: 14px; }
    .ethereumcard-purchase-content04-item:nth-of-type(1) {
      padding: 20px; }
    .ethereumcard-purchase-content04-item:nth-of-type(2) {
      padding: 10px; }
    .ethereumcard-purchase-content04-item > img {
      width: 300px; }
    .ethereumcard-purchase-content04-item > li {
      padding-top: 20px;
      font-family: NotoSansBold;
      font-size: 16px; }
    .ethereumcard-purchase-content04-item > div {
      text-indent: 26px; }
  .support-container {
    text-align: center;
    width: 100%;
    height: 450px;
    background-color: #f4f4f4;
    margin-top: -5px; }
  .support-category-title {
    font-family: NotoSansBold;
    font-size: 30px;
    color: #333333;
    padding-top: 55px; }
  .support-category-title-sub {
    font-family: NotoSansMedium;
    font-size: 18px;
    color: #999999;
    padding-bottom: 35px; }
  .support-category-link {
    background-color: #fff;
    width: 150px;
    height: 110px;
    cursor: pointer;
    display: inline-table;
    margin: 5px 5px; }
  .support-category-link-image {
    padding-top: 10px; }
  .support-category-link-image > img {
    width: 50px; }
  .support-category-link-name {
    font-family: NotoSansBold;
    font-size: 18px;
    color: #333333; }
  .support-category-link-detail01 {
    font-family: NotoSansMedium;
    font-size: 12px;
    color: #999999; }
  .support-category-link-detail02 {
    font-family: NotoSansBlack;
    font-size: 14px;
    color: #999999; }
  .support-sub-title {
    background-image: url("../img/support/bl_sub_title.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 20px;
    font-size: 28px;
    font-family: NotoSansBold;
    color: #333333;
    margin: 40px 0px 20px 0px; }
  .support-breadcrumb-text {
    font-size: 14px;
    letter-spacing: -1px;
    color: #00aab6;
    font-weight: bold;
    width: 45%; }
  th {
    font-family: NotoSansBold; }
  td {
    font-family: NotoSansRegular; }
  th.support-column-title {
    text-align: center !important;
    letter-spacing: -1px;
    color: #2c2c2c;
    font-weight: bold !important; }
  td.support-column-title {
    text-align: left !important;
    font-size: 13px;
    letter-spacing: -1px;
    color: #898989;
    line-height: 21px; }
  th.support-column {
    text-align: center !important;
    letter-spacing: -1px;
    color: #2c2c2c;
    font-weight: bold !important;
    width: 100px; }
  td.support-column {
    text-align: center !important;
    font-size: 13px;
    letter-spacing: -1px;
    color: #898989;
    line-height: 21px;
    width: 100px; }
  th.support-column-number {
    text-align: center !important;
    letter-spacing: -1px;
    color: #2c2c2c;
    font-weight: bold !important;
    width: 150px; }
  td.support-column-number {
    text-align: center !important;
    font-size: 13px;
    letter-spacing: -1px;
    color: #898989;
    line-height: 21px;
    width: 150px; }
  .support-column-btn {
    font-size: 13px;
    letter-spacing: -1px;
    color: #898989;
    line-height: 21px;
    margin: 5px; }
  .support-table-top-line {
    height: 2px;
    background-color: #00aab6;
    width: 100%; }
  .ant-table-expand-icon-th,
  .ant-table-row-expand-icon-cell {
    padding: 4px !important;
    width: 30px !important;
    min-width: 0px !important; }
  .ant-table-expanded-row {
    font-family: NotoSansRegular;
    font-size: 13px !important; }
  .modal-wrapper {
    width: 320px;
    font-size: 20px;
    font-family: NotoSansRegular; }
  .login-title-wrapper {
    font-size: 30px;
    font-family: NotoSansMedium;
    color: #333333;
    text-align: center;
    margin: 40px 0px 20px 0px; }
  .login-form-wrapper {
    border: solid 1px #d0d0d0;
    border-radius: 10px;
    width: 330px;
    display: inline-block;
    margin: 30px 0px;
    font-size: 14px;
    font-family: NotoSansRegular;
    color: #333333;
    padding: 20px; }
  .login-text-login {
    font-size: 28px;
    font-family: NotoSansBold;
    color: #00aab6; }
  .login-text-sub {
    font-size: 14px;
    font-family: NotoSansRegular;
    color: #333333;
    margin: 10px 0px 30px 0px; }
  .login-form-left {
    width: 100%;
    display: inline-block; }
  .login-form-right {
    width: 100%;
    display: inline-block; }
  .login-item-wrapper {
    text-align: left; }
  .login-field-icon {
    float: left;
    position: absolute;
    z-index: 10;
    padding-left: 8px; }
  .login-field-input input {
    height: 46px;
    text-indent: 40px; }
  .login-field-checkbox {
    text-align: left;
    padding: 10px 5px; }
  .login-btn {
    font-size: 18px !important;
    width: 100%;
    height: 60px !important;
    background-color: #00aab6 !important;
    color: #fff !important;
    border-width: 0px !important;
    margin: -20px 0px 20px 0px;
    font-family: NotoSansRegular !important; }
  .login-btn-sub-wrapper {
    display: inline-block;
    margin-bottom: 50px; }
  .kakao-login-btn-size {
    width: 290px; }
  .naver-login-btn-size {
    width: 290px; }
  .notfount-wrapper {
    margin: auto;
    width: 300px;
    font-size: 14px;
    font-family: NotoSansRegular;
    color: #333333; }
  .notfound-header {
    margin: 45px 0px 20px 0px;
    font-size: 28px;
    font-family: NotoSansBold;
    color: #00aab6; }
  .notfount-title {
    font-size: 24px;
    font-family: NotoSansBold;
    color: #00aab6; }
  .notfount-btn {
    font-size: 20px;
    background-color: #00aab6;
    color: #fff;
    width: 200px;
    height: 50px;
    cursor: pointer;
    padding-top: 10px;
    font-family: NotoSansRegular !important; }
  .signup-agreement-wrapper {
    text-align: left;
    padding: 10px 0px; }
  .signup-agreement-title {
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333;
    padding: 10px 0px; }
  .signup-btn-wrapper {
    padding: 20px 0px;
    width: 100%; }
  .signup-btn {
    width: 47% !important;
    height: 40px !important;
    border-width: 0px !important;
    color: #fff !important;
    font-size: 18px !important;
    font-family: NotoSansRegular !important; }
  .signup-btn-ok {
    float: left;
    background-color: #00aab6 !important; }
  .signup-btn-cancel {
    float: right;
    background-color: #575757 !important; }
  .signup-other-login-wrapper {
    text-align: left;
    padding: 20px 0px; }
  .signup-other-login-text {
    font-size: 16px;
    padding: 10px 0px; }
  .signup-other-login-btn-wrapper01 {
    margin-bottom: 10px; }
  .order-container {
    width: 330px;
    display: inline-block; }
  .order-left-container {
    width: 330px; }
  .order-right-container {
    width: 330px; }
  .order-form-wrapper {
    border: solid 1px #d0d0d0;
    border-radius: 10px;
    width: 100%;
    display: inline-block;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333;
    padding: 20px;
    margin: 20px 0px;
    text-align: left; }
  .order-component-title {
    font-size: 24px;
    font-family: NotoSansBold;
    color: #00aab6;
    margin-top: 10px;
    text-align: center; }
  .order-component-text {
    padding-bottom: 20px;
    text-align: center; }
  .order-component-text-bold {
    font-size: 18px;
    font-family: NotoSansBold;
    color: #333333;
    text-align: left; }
  .order-component-check {
    position: absolute;
    z-index: 1;
    margin-top: -5px; }
  .order-field-icon {
    float: left;
    position: absolute;
    z-index: 10;
    padding: 7px; }
  .order-goods-title {
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #999999;
    text-align: left;
    padding: 30px 0px 10px 0px; }
  .order-goods-title-bold {
    font-size: 24px;
    font-family: NotoSansBold;
    color: #00aab6;
    margin-top: 10px; }
  .order-goods-text-wrapper {
    text-align: left;
    padding: 10px; }
  .order-goods-text-left {
    display: inline-block;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333;
    width: 100px; }
  .order-goods-text-right {
    display: inline-block;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333; }
  .order-goods-text-bold {
    font-size: 24px;
    font-family: NotoSansBold;
    color: #ff6e00; }
  .order-pay-wrapper {
    background-color: #f8f9f9;
    border-top: solid 2px #d0d0d0;
    border-bottom: solid 2px #d0d0d0;
    text-align: left;
    padding: 10px 20px;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333; }
  .order-agreement-wrapper {
    padding: 20px 0px;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333;
    text-align: left; }
  .purchase-component-btn {
    margin-top: 50px;
    font-size: 16px !important;
    width: 100%;
    height: 50px !important;
    background-color: #00aab6 !important;
    color: #fff !important;
    border-width: 0px !important;
    font-family: NotoSansRegular;
    margin: 10px 0px 10px 0px; }
  .purchase-result-item-wrapper {
    text-align: left;
    padding: 10px 20px;
    line-height: 24px;
    font-size: 16px;
    font-family: NotoSansRegular; }
  .purchase-result-item-left {
    display: inline-block;
    width: 100px; }
  .purchase-result-item-right {
    display: inline-block;
    font-family: NotoSansLight; }
  .purchase-result-button {
    font-size: 18px !important;
    width: 100%;
    height: 60px !important;
    background-color: #00aab6 !important;
    color: #fff !important;
    border-width: 0px !important;
    margin: -20px 0px 20px 0px; }
  .purchaselist-image {
    display: inline-block;
    width: 100%; }
  .purchaselist-detail {
    margin: 20px 0px 0px -20px;
    display: inline-block;
    line-height: 24px;
    font-size: 16px;
    font-family: NotoSansRegular; } }
