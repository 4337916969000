@font-face {
  font-family: 'Noto Sans Kr Light';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Light'), local('NotoSans-Light'),
    url(/NotoSans-Light.eot);
  src: url(/NotoSans-Light.eot?#iefix) format('embedded-opentype'),
    url(/NotoSans-Light.woff2) format('woff2'),
    url(/NotoSans-Light.woff) format('woff');
}
@font-face {
  font-family: 'Noto Sans Kr Medium';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Medium'), local('NotoSans-Medium'),
    url(/NotoSans-Lighter.eot);
  src: url(/NotoSans-Medium.eot?#iefix) format('embedded-opentype'),
    url(/NotoSans-Medium.woff2) format('woff2'),
    url(/NotoSans-Medium.woff) format('woff');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
