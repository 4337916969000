@charset "UTF-8";
html {
  scroll-behavior: smooth;
  font-size: 14px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: auto !important; }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  word-break: keep-all; }

#root {
  height: 100%; }

.app {
  display: flex;
  flex-direction: column; }

.ant-checkbox-checked .ant-checkbox-inner, .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #00aab6 !important;
  border-color: #00aab6 !important; }

.ant-checkbox-inner {
  border: 1px solid #898989 !important; }

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 100; }

/*=============================================
=           데스크탑            =
=============================================*/
@media screen and (min-width: 1090px) {
  .mobile {
    display: none; }
  .app {
    position: relative; }
  .invisible {
    display: none; }
  .container {
    max-width: 1340px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px; }
  .container-support {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px; }
  .menu-line {
    height: 1px;
    width: 100%;
    background-color: #d0d0d0;
    margin-top: -5px; }
  .breadcrumb {
    font-family: NotoSansRegular;
    font-size: 16px;
    color: #979797;
    padding: 12px 0px; }
    .breadcrumb-current {
      color: #646464;
      font-weight: bold; }
    .breadcrumb-divider {
      margin: 0px 12px; }
  .top-link {
    height: 40px;
    font-family: NotoSansRegular;
    font-size: 16px;
    color: #333333; }
  .top-link-item {
    float: right;
    padding: 5px 10px;
    color: #333333;
    cursor: pointer; }
  .top-link-divider {
    float: right;
    padding: 5px 10px; }
  .header {
    height: 72px; }
    .header-logo {
      float: left;
      padding-top: 13px; }
    .header-menu {
      float: right;
      padding-top: 13px; }
  .ant-menu {
    font-family: NanumSquareEB !important;
    font-size: 20px !important;
    color: #333333 !important;
    background-color: transparent !important; }
  .ant-menu-item {
    margin-right: 80px; }
  .ant-menu-item > a {
    color: #333333 !important; }
  .ant-menu-item > a:hover {
    color: #00aab6 !important; }
  .ant-menu-submenu-title:hover {
    color: #00aab6 !important; }
  .ant-menu-item-selected {
    color: #00aab6 !important; }
  .ant-menu-item-selected > a,
  .ant-menu-item-selected > a:hover {
    color: #00aab6 !important; }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background: transparent !important; }
  .ant-menu-horizontal {
    line-height: 54px !important;
    border-bottom: 5px !important; }
  .ant-menu-horizontal > .ant-menu-item {
    margin: 0px 50px; }
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    padding-bottom: 0px !important;
    border-bottom: 5px solid #00aab6 !important;
    color: #00aab6 !important; }
  .ant-menu-submenu-popup {
    color: #ffffff !important;
    background-color: #090e2c !important; }
  .ant-menu-horizontal {
    border-bottom: 5px solid transparent !important; }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 5px solid transparent; }
  .footer {
    height: 200px;
    background-color: #000;
    font-family: NotoSansRegular;
    font-size: 22px;
    line-height: 32px;
    color: #fff; }
    .footer-left {
      float: left;
      padding-top: 60px; }
    .footer-right {
      float: left;
      padding-left: 50px;
      padding-top: 45px; }
  .purchase-container-left {
    float: left; }
  .purchase-container-right {
    float: right;
    width: 556px;
    margin-top: -10px; }
  .purchase-image-main {
    width: 643px;
    height: 418px; }
  .purchase-image-sub-list {
    margin: 10px 0px 0px -5px; }
  .purchase-image-sub-wrapper {
    display: inline; }
  .purchase-image-sub {
    width: 207px;
    height: 135px;
    margin: 0px 5px; }
  .purchase-content-detail-quantity {
    width: 100px; }
  .purchase-content-line {
    height: 1px;
    background-color: #d0d0d0; }
  .purchase-content-title-name {
    display: inline-block;
    font-family: NotoSansRegular;
    font-size: 35px;
    font-weight: bold; }
  .purchase-content-title-engname {
    display: inline-block;
    font-family: NotoSansRegular;
    font-size: 30px;
    color: #bebebe;
    margin-left: 20px; }
  .purchase-content-title-explain {
    font-family: NotoSansMedium;
    font-size: 20px;
    padding-bottom: 15px; }
  .purchase-content-price {
    font-family: NotoSansBlack;
    font-size: 30px;
    color: #00aab6;
    padding: 15px 0px; }
  .purchase-content-detail-wrapper {
    padding: 15px 0px; }
  .purchase-content-detail-item {
    display: inline-block;
    width: 130px;
    font-family: NotoSansMedium;
    font-size: 18px;
    line-height: 35px; }
  .purchase-content-detail-item-explain {
    display: inline-block;
    font-family: NotoSansRegular;
    font-size: 18px;
    line-height: 35px; }
  .purchase-content-totalprice-wrapper {
    padding: 15px 0px; }
  .purchase-content-totalprice-01 {
    display: inline-block;
    font-family: NotoSansBold;
    font-size: 22px; }
  .purchase-content-totalprice-02 {
    display: inline-block;
    font-family: NotoSansRegular;
    font-size: 18px; }
  .purchase-content-totalprice-03 {
    display: inline-block;
    font-family: NotoSansBlack;
    font-size: 22px;
    color: #ff6e00; }
  .purchase-content-button {
    font-family: NanumSquareB;
    font-size: 24px;
    color: #fff;
    width: 100%;
    background-color: #00aab6;
    padding: 15px;
    text-align: center;
    cursor: pointer; }
  .blobscard-top {
    background-image: url("../img/blobscard/main_title_bg.png");
    height: 560px;
    width: 100%;
    margin-top: -5px; }
  .blobscard-title {
    width: 100%;
    text-align: center;
    font-size: 60px;
    font-family: NotoSansMedium;
    font-weight: bold;
    padding-top: 150px; }
  .blobscard-title-explain {
    width: 100%;
    text-align: center;
    font-size: 28px;
    font-family: NotoSansRegular;
    padding-top: 30px; }
  .blobscard-title-button {
    font-family: NanumSquareB;
    font-size: 24px;
    text-align: center;
    padding-top: 30px; }
    .blobscard-title-button-01 {
      color: #fff;
      display: inline-block;
      width: 255px;
      padding: 15px;
      background-color: #00aab6;
      margin: 8px;
      cursor: pointer; }
    .blobscard-title-button-02 {
      color: #fff;
      display: inline-block;
      width: 255px;
      padding: 15px;
      background-color: #000;
      margin: 8px;
      cursor: pointer; }
  .blobscard-content-left {
    width: 700px;
    float: left; }
  .blobscard-content-right {
    width: 600px;
    float: left; }
  .blobscard-content-number {
    font-family: NanumSquareB;
    font-size: 30px;
    color: #00aab6; }
  .blobscard-content-title {
    font-family: NotoSansRegular;
    font-size: 45px;
    color: #333333; }
  .blobscard-content-explain {
    font-family: NotoSansRegular;
    font-size: 28px;
    line-height: 40px;
    color: #646464;
    padding-top: 30px; }
  .blobscard-content-02-wrapper {
    background-color: #f9fafb; }
  .blobscard-content-04-wrapper {
    background-image: url("../img/blobscard/main_content04_bg.png"); }
  .blobscard-purchase-content-tab {
    background-color: #eaeaea;
    border: solid 1px #d0d0d0;
    font-family: NotoSansRegular;
    font-size: 22px;
    color: #333333;
    display: inline-block;
    padding: 20px;
    text-align: center;
    width: 230px;
    cursor: pointer; }
    .blobscard-purchase-content-tab-over {
      background-color: #fff;
      border: solid 1px #d0d0d0;
      font-family: NotoSansBold;
      font-size: 22px;
      color: #333333;
      display: inline-block;
      padding: 20px;
      text-align: center;
      width: 230px;
      cursor: pointer; }
  .support-coin-container {
    margin: 0 auto;
    width: 80%;
    font-size: 14px;
    letter-spacing: -1px;
    color: #575757;
    font-weight: bold;
    text-align: center; }
  .support-coin-wrapper {
    display: inline-block;
    vertical-align: top;
    padding: 50px 0px;
    width: 200px; }
  .support-coin-image {
    width: 78%; }
  .support-coin-name {
    font-family: NotoSansRegular;
    font-size: 14px;
    padding-top: 10px; }
  .blobscard-purchase-coin-title {
    font-family: NotoSansBold;
    font-size: 45px;
    color: #333333;
    padding: 100px 0px 20px 0px; }
    .blobscard-purchase-coin-title-01 {
      display: inline-block; }
    .blobscard-purchase-coin-title-02 {
      display: inline-block;
      color: #00aab6;
      margin-left: 10px; }
  .blobscard-purchase-coin-explain-01 {
    font-family: NotoSansRegular;
    font-size: 25px;
    color: #333333; }
  .blobscard-purchase-coin-explain-02 {
    font-family: NotoSansRegular;
    font-size: 22px;
    color: #ff832a;
    padding-bottom: 20px; }
  .blobscard-purchase-content01 {
    min-height: 550px;
    background-image: url("../img/ethereumcard/purchase_content01_bg.png");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: 0px; }
  .blobscard-purchase-content01-title {
    font-size: 55px;
    color: #00aab6;
    font-family: NotoSansBold;
    text-align: center;
    padding: 170px 0px 25px 0px; }
  .blobscard-purchase-content01-explain {
    font-size: 25px;
    color: #fff;
    font-family: NotoSansRegular;
    text-align: center; }
  .blobscard-purchase-content01-box-wrapper {
    padding: 90px 0px;
    margin: 0px auto;
    width: 900px; }
  .blobscard-purchase-content01-box {
    display: inline-block;
    text-align: center;
    width: 200px;
    height: 200px;
    background-color: #def2f3;
    margin: 6px; }
    .blobscard-purchase-content01-box:nth-child(2n) {
      background-color: #ededed; }
  .blobscard-purchase-content01-box-number {
    font-size: 27px;
    color: #00aab6;
    font-family: NotoSansBlack;
    padding-top: 20px; }
  .blobscard-purchase-content01-box-title {
    font-size: 27px;
    color: #333333;
    font-family: NotoSansBold; }
  .blobscard-purchase-content01-box-detail {
    font-size: 20px;
    color: #646464;
    font-family: NotoSansRegular;
    padding: 20px 5px; }
  .blobscard-purchase-content02-content {
    display: inline-block;
    margin: 30px;
    width: 320px;
    vertical-align: top; }
  .blobscard-purchase-content03-wrapper {
    background-image: url("../img/blobscard/purchase_content03_bg.png");
    background-repeat: no-repeat;
    height: 600px;
    text-align: center;
    padding-top: 130px; }
  .blobscard-purchase-content03-title01 {
    font-size: 45px;
    line-height: 45px;
    color: #00aab6;
    font-family: NotoSansRegular; }
  .blobscard-purchase-content03-title02 {
    font-size: 55px;
    line-height: 65px;
    color: #00aab6;
    font-family: NotoSansBold;
    padding-bottom: 30px; }
  .blobscard-purchase-content03-explain {
    font-size: 25px;
    color: #fff;
    font-family: NotoSansRegular; }
  .blobscard-purchase-content04-title01 {
    font-size: 30px;
    color: #333333;
    font-family: NotoSansRegular;
    text-align: center;
    padding-top: 100px; }
  .blobscard-purchase-content04-title02 {
    font-size: 45px;
    color: #00aab6;
    font-family: NotoSansRegular;
    text-align: center; }
  .blobscard-purchase-content04-image {
    padding-top: 40px;
    padding-bottom: 100px; }
  .blobscard-purchase-content05-wrapper {
    padding-top: 100px; }
  .blobscard-purchase-content05-item {
    display: inline-block;
    color: #646464;
    font-family: NotoSansBold;
    font-size: 22px;
    text-align: center;
    margin-right: 50px; }
    .blobscard-purchase-content05-item > div {
      padding-top: 20px; }
  .blobscard-purchase-content06-wrapper {
    padding: 100px 0px; }
  .blobscard-purchase-content06-container {
    background-color: #eeeeee;
    width: 100%;
    height: 650px; }
  .blobscard-purchase-content06-item {
    float: left;
    color: #646464;
    font-family: NotoSansRegular;
    font-size: 22px; }
    .blobscard-purchase-content06-item:nth-of-type(1) {
      padding: 150px 0px; }
    .blobscard-purchase-content06-item:nth-of-type(2) {
      padding: 10px 0px; }
    .blobscard-purchase-content06-item > img {
      vertical-align: unset;
      margin: 0px 100px 0px 150px; }
    .blobscard-purchase-content06-item > li {
      padding-top: 50px;
      font-family: NotoSansBold;
      font-size: 24px; }
    .blobscard-purchase-content06-item > div {
      text-indent: 33px; }
  .blobscard-purchase-content07-explain {
    color: #646464;
    font-family: NotoSansMedium;
    font-size: 22px;
    padding-left: 20px; }
  .blobscard-purchase-content07-container {
    text-align: center;
    padding-bottom: 100px; }
  .ethereumcard-top {
    background-image: url("../img/ethereumcard/main_title_bg.png");
    height: 560px;
    width: 100%;
    margin-top: -5px; }
  .ethereumcard-title {
    width: 100%;
    font-size: 60px;
    font-family: NotoSansMedium;
    font-weight: bold;
    color: #fff;
    padding-top: 150px; }
  .ethereumcard-title-explain {
    width: 100%;
    font-size: 28px;
    color: #fff;
    font-family: NotoSansRegular;
    padding-top: 30px; }
  .ethereumcard-title-button {
    font-family: NanumSquareB;
    font-size: 24px;
    padding-top: 30px; }
    .ethereumcard-title-button-01 {
      text-align: center;
      color: #fff;
      display: inline-block;
      width: 255px;
      padding: 15px;
      background-color: #00aab6;
      margin: 8px;
      cursor: pointer; }
    .ethereumcard-title-button-02 {
      text-align: center;
      color: #fff;
      display: inline-block;
      width: 255px;
      padding: 15px;
      background-color: #000;
      margin: 8px;
      cursor: pointer; }
  .ethereumcard-content-gray-wrapper {
    background-color: #f7f7f8; }
  .ethereumcard-purchase-content01 {
    margin-top: 126px;
    min-height: 550px;
    background-image: url("../img/ethereumcard/purchase_content01_bg.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 0px; }
  .ethereumcard-purchase-content01-title {
    font-size: 55px;
    color: #00aab6;
    font-family: NotoSansBold;
    text-align: center;
    padding: 170px 0px 25px 0px; }
  .ethereumcard-purchase-content01-explain {
    font-size: 25px;
    color: #fff;
    font-family: NotoSansRegular;
    text-align: center; }
  .ethereumcard-purchase-content01-box-wrapper {
    padding: 90px 0px;
    margin: 0px auto;
    width: 900px; }
  .ethereumcard-purchase-content01-box {
    display: inline-block;
    text-align: center;
    width: 200px;
    height: 200px;
    background-color: #def2f3;
    margin: 6px; }
    .ethereumcard-purchase-content01-box:nth-child(2n) {
      background-color: #ededed; }
  .ethereumcard-purchase-content01-box-number {
    font-size: 27px;
    color: #00aab6;
    font-family: NotoSansBlack;
    padding-top: 20px; }
  .ethereumcard-purchase-content01-box-title {
    font-size: 27px;
    color: #333333;
    font-family: NotoSansBold; }
  .ethereumcard-purchase-content01-box-detail {
    font-size: 20px;
    color: #646464;
    font-family: NotoSansRegular;
    padding: 20px 5px; }
  .ethereumcard-purchase-slash {
    background-image: url("../img/common/bullet_slash.png");
    background-repeat: repeat-x;
    background-position-y: 50%;
    text-align: center; }
  .ethereumcard-purchase-content02-title {
    font-size: 30px;
    color: #333333;
    font-family: NotoSansBold;
    background-color: #fff;
    display: inline;
    letter-spacing: 10px;
    padding: 20px; }
  .ethereumcard-purchase-content02-content-wrapper {
    padding: 50px;
    text-align: center; }
  .ethereumcard-purchase-content02-content {
    display: inline-block;
    margin: 30px;
    width: 320px; }
  .ethereumcard-purchase-content02-content-item02 {
    text-align: left;
    padding-left: 25px;
    background-image: url("../img/common/bullet_check.png");
    background-repeat: no-repeat;
    background-position-y: 6px;
    color: #646464;
    font-family: NotoSansRegular;
    font-size: 20px;
    margin-top: 20px; }
  .ethereumcard-purchase-content03-wrapper {
    padding-top: 100px; }
  .ethereumcard-purchase-content03-item {
    display: inline-block;
    color: #646464;
    font-family: NotoSansBold;
    font-size: 22px;
    text-align: center;
    margin-right: 150px; }
    .ethereumcard-purchase-content03-item > div {
      padding-top: 20px; }
  .ethereumcard-purchase-content04-wrapper {
    padding: 100px 0px; }
  .ethereumcard-purchase-content04-container {
    background-color: #eeeeee;
    width: 100%;
    height: 450px; }
  .ethereumcard-purchase-content04-item {
    float: left;
    color: #646464;
    font-family: NotoSansRegular;
    font-size: 22px; }
    .ethereumcard-purchase-content04-item:nth-of-type(1) {
      padding: 100px 0px; }
    .ethereumcard-purchase-content04-item:nth-of-type(2) {
      padding: 50px 0px; }
    .ethereumcard-purchase-content04-item > img {
      vertical-align: unset;
      margin: 0px 100px 0px 150px; }
    .ethereumcard-purchase-content04-item > li {
      padding-top: 50px;
      font-family: NotoSansBold;
      font-size: 24px; }
    .ethereumcard-purchase-content04-item > div {
      text-indent: 33px; }
  .support-container {
    text-align: center;
    width: 100%;
    height: 500px;
    background-color: #f4f4f4;
    margin-top: -5px; }
  .support-category-title {
    font-family: NotoSansBold;
    font-size: 45px;
    color: #333333;
    padding-top: 75px; }
  .support-category-title-sub {
    font-family: NotoSansMedium;
    font-size: 28px;
    color: #999999; }
  .support-category-link {
    background-color: #fff;
    width: 250px;
    height: 200px;
    cursor: pointer;
    display: inline-table;
    margin: 50px 18px; }
  .support-category-link-image {
    padding-top: 25px; }
  .support-category-link-name {
    font-family: NotoSansBold;
    font-size: 28px;
    color: #333333; }
  .support-category-link-detail01 {
    font-family: NotoSansMedium;
    font-size: 20px;
    color: #999999; }
  .support-category-link-detail02 {
    font-family: NotoSansBlack;
    font-size: 24px;
    color: #999999; }
  .support-sub-title {
    background-image: url("../img/support/bl_sub_title.png");
    background-repeat: no-repeat;
    background-position-y: 50%;
    text-indent: 20px;
    font-size: 35px;
    font-family: NotoSansBold;
    color: #333333;
    margin: 60px 0px 30px 0px; }
  .support-breadcrumb-text {
    font-size: 16px;
    letter-spacing: -1px;
    color: #00aab6;
    font-weight: bold;
    width: 45%; }
  th {
    font-family: NotoSansBold; }
  td {
    font-family: NotoSansRegular; }
  th.support-column-title {
    text-align: center !important;
    letter-spacing: -1px;
    color: #2c2c2c;
    font-weight: bold !important; }
  td.support-column-title {
    text-align: left !important;
    font-size: 13px;
    letter-spacing: -1px;
    color: #898989;
    line-height: 21px; }
  th.support-column {
    text-align: center !important;
    letter-spacing: -1px;
    color: #2c2c2c;
    font-weight: bold !important;
    width: 180px; }
  td.support-column {
    text-align: center !important;
    font-size: 13px;
    letter-spacing: -1px;
    color: #898989;
    line-height: 21px;
    width: 180px; }
  th.support-column-number {
    text-align: center !important;
    letter-spacing: -1px;
    color: #2c2c2c;
    font-weight: bold !important;
    width: 150px; }
  td.support-column-number {
    text-align: center !important;
    font-size: 13px;
    letter-spacing: -1px;
    color: #898989;
    line-height: 21px;
    width: 150px; }
  .support-column-btn {
    font-size: 13px;
    letter-spacing: -1px;
    color: #898989;
    line-height: 21px;
    margin: 5px; }
  .support-table-top-line {
    height: 2px;
    background-color: #00aab6;
    width: 100%; }
  .modal-wrapper {
    width: 500px;
    font-size: 20px;
    font-family: NotoSansRegular; }
  .login-title-wrapper {
    font-size: 45px;
    font-family: NotoSansMedium;
    color: #333333;
    text-align: center;
    margin: 80px 0px 20px 0px; }
  .login-form-wrapper {
    border: solid 1px #d0d0d0;
    border-radius: 10px;
    width: 900px;
    display: inline-block;
    margin: 50px 0px;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333; }
  .login-text-login {
    font-size: 36px;
    font-family: NotoSansBold;
    color: #00aab6;
    margin-top: 20px; }
  .login-text-sub {
    font-size: 18px;
    font-family: NotoSansRegular;
    color: #333333;
    margin: 10px 0px 30px 0px; }
  .login-form-left {
    width: 400px;
    display: inline-block;
    margin-left: 40px; }
  .login-form-right {
    width: 400px;
    display: inline-block;
    margin-left: 40px;
    vertical-align: top; }
  .login-item-wrapper {
    text-align: left; }
  .login-field-icon {
    float: left;
    position: absolute;
    z-index: 10;
    padding-left: 8px; }
  .login-field-input input {
    height: 46px;
    text-indent: 40px; }
  .login-field-checkbox {
    text-align: left;
    padding: 10px 5px; }
  .login-btn {
    font-size: 18px !important;
    width: 100%;
    height: 60px !important;
    background-color: #00aab6 !important;
    color: #fff !important;
    border-width: 0px !important;
    margin: -20px 0px 20px 0px;
    font-family: NotoSansRegular !important; }
  .login-btn-sub-wrapper {
    display: inline-block;
    margin-bottom: 50px; }
  .notfount-wrapper {
    margin: auto;
    width: 600px;
    font-size: 18px;
    font-family: NotoSansRegular;
    color: #333333; }
  .notfound-header {
    margin: 145px 0px 50px 0px;
    font-size: 36px;
    font-family: NotoSansBold;
    color: #00aab6; }
  .notfount-title {
    font-size: 24px;
    font-family: NotoSansBold;
    color: #00aab6; }
  .notfount-btn {
    font-size: 24px;
    background-color: #00aab6;
    color: #fff;
    width: 200px;
    height: 60px;
    cursor: pointer;
    padding-top: 10px; }
  .signup-form {
    padding: 0px 30px 30px 30px; }
  .signup-agreement-wrapper {
    text-align: left;
    padding: 10px 0px; }
  .signup-agreement-title {
    font-size: 18px;
    font-family: NotoSansRegular;
    color: #333333;
    padding: 10px 0px; }
  .signup-btn-wrapper {
    padding: 20px 0px;
    width: 100%; }
  .signup-btn {
    width: 47% !important;
    height: 60px !important;
    border-width: 0px !important;
    color: #fff !important;
    font-size: 24px !important;
    font-family: NotoSansRegular !important; }
  .signup-btn-ok {
    float: left;
    background-color: #00aab6 !important; }
  .signup-btn-cancel {
    float: right;
    background-color: #575757 !important; }
  .signup-other-login-wrapper {
    text-align: left;
    padding: 20px 0px; }
  .signup-other-login-text {
    font-size: 18px;
    padding: 10px 0px; }
  .signup-other-login-btn-wrapper01 {
    float: left;
    margin-bottom: 50px;
    width: 47%; }
    .signup-other-login-btn-wrapper01 img {
      width: 100% !important; }
  .signup-other-login-btn-wrapper02 {
    float: right;
    margin-bottom: 50px;
    width: 47%; }
    .signup-other-login-btn-wrapper02 > img {
      width: 100% !important; }
  .order-container {
    width: 1200px;
    display: inline-block;
    padding: 30px; }
  .order-left-container {
    width: 600px;
    float: left; }
  .order-right-container {
    width: 500px;
    float: right; }
  .order-form-wrapper {
    border: solid 1px #d0d0d0;
    border-radius: 10px;
    width: 100%;
    display: inline-block;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333;
    padding: 20px;
    margin: 20px 0px;
    text-align: left; }
  .order-component-title {
    font-size: 24px;
    font-family: NotoSansBold;
    color: #00aab6;
    margin-top: 10px;
    text-align: center; }
  .order-component-text {
    padding-bottom: 20px;
    text-align: center; }
  .order-component-text-bold {
    font-size: 18px;
    font-family: NotoSansBold;
    color: #333333;
    text-align: left; }
  .order-component-check {
    position: absolute;
    z-index: 1; }
  .order-field-icon {
    float: left;
    position: absolute;
    z-index: 10;
    padding: 7px; }
  .order-goods-title {
    font-size: 20px;
    font-family: NotoSansRegular;
    color: #999999;
    text-align: left;
    padding: 30px 0px 10px 0px; }
  .order-goods-title-bold {
    font-size: 30px;
    font-family: NotoSansBold;
    color: #00aab6;
    margin-top: 10px; }
  .order-goods-text-wrapper {
    text-align: left;
    padding: 10px; }
  .order-goods-text-left {
    display: inline-block;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333;
    width: 100px; }
  .order-goods-text-right {
    display: inline-block;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333; }
  .order-goods-text-bold {
    font-size: 30px;
    font-family: NotoSansBold;
    color: #ff6e00; }
  .order-pay-wrapper {
    background-color: #f8f9f9;
    border-top: solid 2px #d0d0d0;
    border-bottom: solid 2px #d0d0d0;
    text-align: left;
    padding: 10px 20px;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333; }
  .order-agreement-wrapper {
    padding: 20px 0px;
    font-size: 16px;
    font-family: NotoSansRegular;
    color: #333333;
    text-align: left; }
  .purchase-component-btn {
    margin-top: 50px;
    font-size: 18px !important;
    width: 100%;
    height: 60px !important;
    background-color: #00aab6 !important;
    color: #fff !important;
    border-width: 0px !important;
    font-family: NotoSansRegular !important; }
  .purchase-result-item-wrapper {
    text-align: left;
    padding: 50px 100px;
    line-height: 35px;
    font-size: 20px;
    font-family: NotoSansRegular; }
  .purchase-result-item-left {
    display: inline-block;
    width: 200px; }
  .purchase-result-item-right {
    display: inline-block;
    font-family: NotoSansLight; }
  .purchase-result-button {
    font-size: 18px !important;
    width: 300px;
    height: 60px !important;
    background-color: #00aab6 !important;
    color: #fff !important;
    border-width: 0px !important;
    margin: -20px 10px 20px 10px; }
  .purchaselist-image {
    margin: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 400px; }
  .purchaselist-detail {
    margin: 10px;
    display: inline-block;
    vertical-align: middle;
    line-height: 35px;
    font-size: 16px;
    font-family: NotoSansRegular; } }
